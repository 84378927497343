.header-component-card {
  background-color: white;
  box-sizing: border-box;
  width: 95vw;
  margin: 0 2.5vw;
  height: 5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  .avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
  }

  .tip {
    font-weight: bold;
  }
}