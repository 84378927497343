.indicator-wrapper {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1rem;

  .rounds {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .line {
    position: absolute;
    z-index: 0;
    left: 2rem;
    right: 2rem;
    height: 2px;
    background-color: #333;
    bottom: 1.98rem;
  }
}