 .answer-questions-records-page {
   width: 100vw;
   box-sizing: border-box;
   padding-bottom: 5rem;

   .menu {
     padding: 0rem 2rem 1rem;
     height: 3.1rem;
     position: relative;
   }

   .header {
     padding: 0rem 1rem;
     overflow: auto;
     position: relative;

     .img {
       width: 100%;
       vertical-align: middle;
     }

     .result {
       width: 100%;
       box-sizing: border-box;
       padding: 1rem;
       border: 4px solid #333;
       border-radius: 20px;
       background-color: #fff;

       .score {
         display: flex;
         align-items: center;
         justify-content: space-between;

         .user {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;

           .avatar-img {
             width: 4rem;
             height: 4rem;
             border-radius: 2rem;
           }

           .nickname {
             margin-top: 0.5rem;
             font-size: 0.8rem;
             max-width: 4rem;
             overflow: hidden;
             text-overflow: ellipsis;
             white-space: nowrap;
           }
         }

         .text {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: space-between;
           min-height: 5rem;

           .yellow {
             color: #feda43;
             font-size: 1.4rem;
             font-weight: bold;
           }

           .tip {
             font-family: "FZDaHei-B02S";
             font-weight: bold;
             font-size: 1rem;
             text-shadow: 2px 2px 0px #ffdb2c;
             color: #333333;
             text-align: center;
           }
         }
       }
     }
   }

   .buttons {
     margin: 1.5rem 0;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;

     .play {
       // width / height = 5.89
       width: 3rem * 5.89;
       height: 3rem;
       // background-image: url(./play.btn.bg.png);
       background-size: cover;
       color: #fff;
       margin: 0rem 0.6rem;
       text-align: center;
       line-height: 2.5rem;
       font-weight: 600;
       font-size: 1.4rem;
     }
   }
 }

 .records-component {
   .record {
     background-color: #fff;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;
     box-sizing: border-box;
     border-radius: 5px;
     margin: 0 1rem 0.5rem;
     padding: 0.5rem 1rem;

     .avatar {
       width: 2.5rem;
       height: 2.5rem;
       border-radius: 5px;
     }

     .text {
       display: flex;
       flex: 1;
       flex-direction: column;
       align-items: flex-start;
       box-sizing: border-box;
       padding: 0 1rem;
       font-size: 0.8rem;

       .nickname {
         font-weight: bold;
         margin-bottom: 0.3rem;
       }
     }

     .buttons {
       min-height: 3.5rem;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: space-between;
     }

     .answer-correctly-text {
       font-weight: bold;
     }
   }
 }