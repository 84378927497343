.wrapper {
  width: 17.5rem;
  height: 3.4rem;
  line-height: 3.4rem;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  margin: 0.3rem auto;
  position: relative;
  background-image: url(./index.btn.bg.png);
  background-size: cover;
  text-decoration: none;
}