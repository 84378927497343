.small-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.6rem;
  height: 2.17rem;
  line-height: 2.17rem;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  margin: auto auto;
  position: relative;
  background-image: url(./index.btn.small.png);
  background-size: cover;
  text-decoration: none;
}