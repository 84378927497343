.select-answer-component {
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0rem;
  max-width: 640px;
  margin: 0 auto;
  min-height: 10rem;
  background-color: white;
  box-sizing: border-box;
  padding: 1rem 1rem 2rem;
}