.item-indicator {
  min-height: 3rem;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 0.5rem 4rem 0.5rem 0.5rem;

  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
  }

  .bubble {
    position: relative;
    min-height: 1.3rem;
    display: inline-block;
    background-color: #fff;
    border-radius: 0.4rem;
    margin-left: 0.5rem;
    padding: 0.5rem;
    line-height: 1.3rem;
    font-size: 1rem;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      right: 100%;
      top: 0px;
      width: 16px;
      height: 16px;
      border-width: 0;
      border-style: solid;
      border-color: transparent;
      margin-right: -9px;
      border-bottom-width: 10px;
      border-bottom-color: currentColor;
      border-radius: 0 0 0 18px;
      color: #fff;
    }
  }
}