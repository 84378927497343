.round {
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  border-radius: 1.2rem;
  color: #333;
  font-size: 0.8rem;
  border: 2px solid #333;
  text-align: center;
  margin: 0rem 0.3rem;
  background-color: #fff;
}