.answer-item-indicator {

  min-height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 4rem;

  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
  }

  .bubble {
    position: relative;
    display: inline-block;
    background-color: #fff;
    border-radius: 0.4rem;
    margin-right: 0.5rem;
    padding: 0.7rem 0.5rem;
    line-height: 1rem;
    font-size: 1rem;
    min-height: 1.3rem;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 100%;
      top: 0px;
      width: 16px;
      height: 16px;
      border-width: 0;
      border-style: solid;
      border-color: transparent;
      margin-left: -9px;
      border-bottom-width: 10px;
      border-bottom-color: currentColor;
      border-radius: 0 0 18px 0;
      color: #fff;
    }
  }
}